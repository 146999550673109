<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Hintergrundwissen</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card>
      <v-card-text>
        <p>
          Neben dem Zahlverständnis sollen die Lernenden in der
          Schuleingangsphase
          <strong>ein tragfähiges Verständnis von Rechenoperationen</strong>
          aufbauen. Die Notwendigkeit dieser Zielsetzung ist aus mindestens zwei
          Gründen gegeben.
        </p>
        <p>
          Erstens hat sich gezeigt, dass ein nicht-tragfähiges
          Operationsverständnis häufig bei denjenigen Lernenden zu beobachten
          ist, die Schwierigkeiten in Mathematik haben (Schipper, 2005). Anders
          herum: Mit Hilfe eines tragfähigen Operationsverständnisses können
          Kinder leichter lernen, Rechenaufgaben richtig zu bearbeiten und sich
          deren Resultate oder Wege zur Ergebnisermittlung dauerhafter zu merken
          (Gaidoschik, 2007, S. 69 f.).
        </p>
        <p>
          Und zweitens wird ein tragfähiges Operationsverständnis immer wieder
          im weiterführenden Mathematikunterricht benötigt: Ein Kind, das über
          keine Vorstellungen von 3 · 5 verfügt, wird mit hoher
          Wahrscheinlichkeit auch keine Vorstellungen von 1,5 · 2,5 oder von
          <sup>3</sup>&frasl;<sub>2</sub> · <sup>5</sup>&frasl;<sub>2</sub> oder
          allgemein von a · b entwickeln können (Selter & Zannetin, 2018).
        </p>
        <p>
          Auf den nächsten drei Seiten werden nun die drei Hintergrundfacetten
          (1. Grundvorstellungen besitzen, 2. Darstellungen vernetzen und 3.
          Aufgabenbeziehungen nutzen) thematisiert.
        </p>
        <br />
        <AppLiteraturOV />
      </v-card-text>
    </v-card>
    <AppBottomNavOV
      back="/operationsverstaendnis"
      next="/operationsverstaendnis/hintergrundwissen/grundvorstellungen"
    />
  </div>
</template>

<script>
import AppBottomNavOV from "@/common/AppBottomNavOV";
import AppLiteraturOV from "@/common/AppLiteraturOV";
export default {
  components: {
    AppBottomNavOV,
    AppLiteraturOV,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
